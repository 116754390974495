import React from 'react'
import Loader from './common/Loader'
import Progress from './common/Progress'

export default function StatisticSalesFunnelProgress({ salesFunnel, loading }) {
    return (
        <div>
            {!loading ? salesFunnel.map((item) => (
                <div key={item.title} className="columns">
                    <span className="column">{item.title} - {item.count}: </span>
                    <span className="column is-four-fifths">
                        <Progress
                            value={item.count}
                            text={item.count}
                            background={item.color}
                            max={salesFunnel[0].count} />
                    </span>
                </div>
            )) : <Loader large />}
        </div>
    )
}
