import React from 'react'
import Layout from '../components/Layout'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'
import { useInfiniteScroll } from '../hooks/request'
import { EMPLOYEE_TIMELINE } from '../urls'
import TimeLineItem from '../components/TimeLineItem'
import RoomFilter from '../components/RoomFilter'
import { useQueryParams } from '../hooks/queryString'
import Schedule from '../components/common/Schedule'
import AcademyHeader from '../components/AcademyHeader'


export default function Timeline() {
    const params = useQueryParams()
    const timelines = useInfiniteScroll({ url: EMPLOYEE_TIMELINE, params })

    if (!cp('academy.view_customer')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <AcademyHeader />

            <RoomFilter params={params} />

            <br />

            <Schedule
                totalCount={timelines.response ? timelines.response.count : 0}
                columns={{
                    teacher: 'Учитель',
                    monday: 'Понедельник',
                    tuesday: 'Вторник',
                    wednesday: 'Среда',
                    thursday: 'Четверг',
                    friday: 'Пятница',
                    saturday: 'Суббота',
                    sunday: 'Воскресенье',
                }}
                items={timelines.response ? timelines.response.results : []}
                renderItem={(item) => (
                    <TimeLineItem
                        onDelete={timelines.request}
                        onUpdate={timelines.request}
                        key={item.id}
                        item={item} />
                )} />

            <div ref={timelines.ref} className="has-text-grey-light is-italic has-text-centered">
                {!timelines.hasMore && !timelines.loading && timelines.length !== 0 ? 'Загрузили всех своевременность' : ''}
            </div>
        </Layout>
    )
}
