import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { checkPermission as cp } from '../utils/auth'
import { useDeleteRequest, usePutRequest } from '../hooks/request'
import Loader from './common/Loader'
import { useMessage } from '../hooks/message'
import { STUDENT_PAYMENT_DATE_DETAIL } from '../urls'
import CustomerInfoPaymentsForm from './CustomerInfoPaymentsForm'
import { getDate } from '../utils/date'

export default function CustomerInfoPaymentsItem({ customer, item, onDelete, onUpdate }) {
    const url = STUDENT_PAYMENT_DATE_DETAIL.replace('{id}', item.id)
    const [showMessage] = useMessage()
    const [edit, setEdit] = useState(false)
    const studentDelete = useDeleteRequest({ url })
    const studentUpdate = usePutRequest({ url })

    async function onStudentUpdate(data) {
        const { success, response } = await studentUpdate.request({ data: { ...data, customer: customer.id } })
        const newCustomer = {
            ...customer,
            paymentsDate: customer.paymentsDate.map((student) => (
                student.id === response.id ? response : student
            )),
        }

        if (success) {
            onUpdate(newCustomer.id, newCustomer)
        }
    }

    async function removeStudent() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await studentDelete.request()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            const newCustomer = { ...customer, paymentsDate: customer.paymentsDate.filter((student) => student !== item) }
            onDelete(newCustomer.id, newCustomer)
        }
    }

    if (edit) {
        return (
            <CustomerInfoPaymentsForm
                onCancel={() => setEdit(false)}
                onSubmit={onStudentUpdate}
                customer={customer}
                initValues={item} />
        )
    }

    return (
        <tr>
            <td>{item.group.name}</td>
            <td>{getDate(item.date)} </td>
            <td>
                {!isEmpty(customer.paymentsDate) ? (
                    <>
                        {item.status === 'payed'
                            ? (
                                <span style={{ marginLeft: '1rem' }} className="tag is-rounded is-link is-light">
                                    Оплачено
                                </span>
                            )
                            : (
                                <span style={{ marginLeft: '1rem' }} className="tag is-rounded is-danger is-light">
                                    Не заплатил
                                </span>
                            )}
                    </>
                ) : null}

            </td>

            <td>
                {cp('academy.change_student') ? (
                    <i onClick={() => setEdit(true)} className="icon pointer ion-md-create" />
                ) : null}

                {cp('academy.delete_student') && !studentDelete.loading ? (
                    <i onClick={removeStudent} className="icon pointer ion-md-trash" />
                ) : null}

                <Loader show={studentDelete.loading} className="icon" />
            </td>
        </tr>
    )
}
