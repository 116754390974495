import React from 'react'
import { getDateTime } from '../utils/date'
import Button from './common/Button'

export default function StudentInfo({ customer, onCancel }) {
    return (
        <div>
            <b>Информация о студенте: {customer.name}</b><br /><br />

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>Ф.И.О:</td>
                        <td>{customer.name}</td>
                    </tr>

                    <tr>
                        <td>Номер телефона:</td>
                        <td>{customer.phone}</td>
                    </tr>

                    <tr>
                        <td>Канал продаж:</td>
                        <td>{customer.advert.name}</td>
                    </tr>

                    <tr>
                        <td>Менеджер продаж:</td>
                        <td>{customer.seller.name}</td>
                    </tr>

                    <tr>
                        <td>Дата добавления:</td>
                        <td>{getDateTime(customer.createdAt)}</td>
                    </tr>

                    <tr>
                        <td>Примечание:</td>
                        <td>{customer.description}</td>
                    </tr>
                </tbody>
            </table>

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger" /> &nbsp;
        </div>
    )
}
