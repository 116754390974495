import React from 'react'
import { usePostRequest } from '../hooks/request'
import { GROUPS_LIST } from '../urls'
import GroupForm from './GroupForm'

export default function GroupCreate({ onCancel, onSuccess }) {
    const groupCreate = usePostRequest({ url: GROUPS_LIST })

    async function onSubmit(data, actions) {
        await groupCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Создать Группу</b><br /><br />
            <GroupForm onSubmit={onSubmit} onCancel={onCancel} loading={groupCreate.loading} />
        </div>
    )
}
