import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import { useLoad } from '../hooks/request'
import { USERS_LIST } from '../urls'
import Input from './common/Input'
import Button from './common/Button'

export default function BranchForm({ onSubmit, onCancel, loading, initialValues }) {
    return (
        <Formik initialValues={{
            name: '',
            phone: '',
            ...initialValues,
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    label="Название филиала"
                    placeholder="Введите название филиала"
                    disabled={loading}
                    validate={required} />
                <Input
                    name="phone"
                    label="Номер телефона"
                    placeholder="Введите номер телефона"
                    disabled={loading}
                    validate={required} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    disabled={loading}
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
