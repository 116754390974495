import React from 'react'
import { usePostRequest } from '../hooks/request'
import SubjectForm from './SubjectForm'
import { SUBJECT_LIST } from '../urls'

export default function SubjectCreate({ onSuccess, onCancel }) {
    const roomCreate = usePostRequest({ url: SUBJECT_LIST })

    async function onSubmit(data, actions) {
        await roomCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <SubjectForm
                loading={roomCreate.loading}
                onCancel={onCancel}
                onSuccess={onSuccess}
                onSubmit={onSubmit} />
        </div>
    )
}
