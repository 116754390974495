import React from 'react'
import { Link, useParams } from 'react-router-dom'
import DateFilter from './common/DateFilter'
import Button from './common/Button'
import Layout from './Layout'

import { useLoad } from '../hooks/request'
import { DASHBOARD, GROUPS_DETAIL } from '../urls'
import { getDateNotRU } from '../utils/date'
import { useQueryParams } from '../hooks/queryString'
import Loader from './common/Loader'
import { useModal } from '../hooks/modal'
import { format } from '../utils/number'

export default function GroupView() {
    const { groupId } = useParams()
    const groupDetail = useLoad({ url: GROUPS_DETAIL.replace('{id}', groupId) })
    const group = groupDetail.response ? groupDetail.response : {}

    const params = useQueryParams()
    const data = useLoad({
        url: DASHBOARD.replace('{groupId}', groupId),
        params: {
            ...params,
        },
    })
    const assessmentDates = data.response ? data.response.assessmentDates : []
    const tableData = data.response ? data.response.tableData : []
    const totalAbsentStudents = data.response ? data.response.totalAbsentStudents : 0
    const comeFromCount = data.response ? data.response.comeFromCount : 0
    const notComeFromCount = data.response ? data.response.notComeFromCount : 0
    const totalPayment = data.response ? data.response.totalPayment : 0


    const [showCalcModal, hideCalcModal] = useModal(
        <div>{
            !groupDetail.loading ? (
                <div>
                    <p style={{ textAlign: 'left' }} className="is-size-4">{group.name}</p>
                    <br />
                    <hgroup style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <DateFilter name="startDate" />
                        <DateFilter name="endDate" />
                    </hgroup> <br />

                    <div className="mb-4">
                        <p className="tag is-medium is-success is-light">Kelganlar soni: {totalAbsentStudents}</p>
                    </div>
                    <br />

                    <div className="mb-4">
                        <p className="tag is-medium is-link is-light">Kelmaganlar (sababli) soni: {comeFromCount}</p>
                    </div>
                    <br />
                    <div className="mb-4">
                        <p className="tag is-medium is-danger is-light">Kelmaganlar (sababsiz)
                            soni: {notComeFromCount}
                        </p>
                    </div>
                    <br />


                    <hr />
                    <div className="mb-4">
                        <p className="tag is-medium is-info is-light">O'qituvchi oladigan
                            summa: {format(totalPayment)}so'm
                        </p>
                    </div>
                    <div className="is-pulled-right" style={{marginTop:'1rem'}}>


                        <Button
                            onClick={() => hideCalcModal()}
                            icon="ion-md-close"
                            text="Отмена"
                            className="is-info" />
                    </div>
                </div>
            ) : <Loader center large />
        }
        </div>,
    )
    return (
        <Layout>
            {!data.loading ? (
                <>
                    <header
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            columnGap: '2rem',
                            marginBottom: '2rem',
                        }}>
                        <Link to="/academy/group" className="button is-white">
                            <i className="icon has-text-link ion-md-arrow-back is-size-4" />
                        </Link>
                        <hgroup style={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent:'flex-end', flexWrap:'wrap' }}>
                            <DateFilter name="startDate" />
                            <DateFilter name="endDate" />
                            <Button
                                onClick={showCalcModal}
                                className="is-outlined is-info"
                                text="Калькулятор"
                                icon="ion-md-calculator"
                            />
                            <Button
                                className="is-outlined is-info"
                                text="Экспорт Excel"
                                icon="ion-md-document"
                            />
                        </hgroup>
                    </header>

                    <div style={{ overflowX: 'scroll' }}>
                        <table className="table is-striped is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th key="students">Ученики</th>
                                    {assessmentDates.map((date, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <th style={{whiteSpace:'nowrap'}} key={index}>{getDateNotRU(date)}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((student, studentIndex) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <tr key={studentIndex}>
                                        <td>{studentIndex + 1}. {student.studentName}</td>
                                        {assessmentDates.map((date, dateIndex) => {
                                            const assessment = student.assessments.find((a) => a.createdAt === date)
                                            // eslint-disable-next-line no-nested-ternary
                                            const isAbsent = assessment ? (assessment.isAbsent
                                                ? (
                                                    <span className="tag is-success is-light">
                                                        Kelgan
                                                    </span>
                                                )

                                                : (
                                                    <>
                                                        {assessment.isAbsentStatus === 'come_from'
                                                            ? (
                                                                <span className="tag is-link is-light">
                                                                    Kelmagan
                                                                    (sababli)
                                                                </span>
                                                            ) : null}
                                                        {assessment.isAbsentStatus === 'not_come_from'
                                                            ? (
                                                                <span className="tag is-danger is-light">
                                                                    Kelmagan
                                                                    (sababsiz)
                                                                </span>
                                                            )
                                                            : null}
                                                        {assessment.isAbsentStatus === null
                                                            ? (
                                                                <span className="tag is-danger is-light">
                                                                    Kelmagan
                                                                </span>
                                                            )
                                                            : null}
                                                    </>
                                                )
                                            )
                                                : 'N/A'

                                            return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <td key={dateIndex}>
                                                    {isAbsent}

                                                </td>
                                            )
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )
                : <Loader center large />}
        </Layout>
    )
}
