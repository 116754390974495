import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_COST_ADVERT } from '../urls'

import StatisticsAcademyCostAdvertBar from './StatisticsAcademyCostAdvertBar'
import Card from './common/Card'


export default function StatisticsAcademyCostAdvert({ startDate, endDate }) {
    const costAdvert = useLoad({ url: STATISTICS_COST_ADVERT, params: { startDate, endDate } })

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                CPA(рекламные расходы на одного нового студента) и CPL(рекламные расходы на одну заявку или звонок)
            </div>

            <StatisticsAcademyCostAdvertBar
                data={costAdvert.response}
                loading={costAdvert.loading} />
        </Card>
    )
}
