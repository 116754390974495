import React from 'react'
import { usePostRequest } from '../hooks/request'
import { STUDENT_LIST } from '../urls'
import CustomerInfoGroupsForm from './CustomerInfoGroupsForm'

export default function CustomerInfoGroupsCreate({ onCancel, onSuccess, customer }) {
    const customerCreate = usePostRequest({ url: STUDENT_LIST })

    async function onSubmit(data, actions) {
        const { success } = await customerCreate.request({ data: { ...data, customer: customer.id } })
        if (success) {
            onSuccess()
            actions.resetForm()
        }
    }

    return (
        <div>
            <b>Добавить нового группа</b><br /><br />

            <CustomerInfoGroupsForm
                onSubmit={onSubmit}
                customer={customer}
                onCancel={onCancel}
                loading={customerCreate.loading} />
        </div>
    )
}
