import React from 'react'
import { STATISTICS_SALES_CHANNEL } from '../urls'
import { useLoad } from '../hooks/request'
import StatisticsAcademySalesChannelChart from './StatisticsAcademySalesChannelChart'
import Card from './common/Card'


export default function StatisticsAcademySalesChannel({ startDate, endDate }) {
    const salesChannel = useLoad({
        url: STATISTICS_SALES_CHANNEL,
        params: { startDate, endDate },
    })

    return (
        <div className="columns">
            <div className="column is-6">
                <Card>
                    <div className="title is-5 has-text-centered">
                        Статистика лидов по каналам продаж
                    </div>

                    <StatisticsAcademySalesChannelChart
                        data={salesChannel.response ? salesChannel.response : []}
                        loading={salesChannel.loading}
                        field="leadsCount" />
                </Card>
            </div>

            <div className="column is-6">
                <Card>
                    <div className="title is-5 has-text-centered">
                        Статистика продаж по каналам
                    </div>

                    <StatisticsAcademySalesChannelChart
                        data={salesChannel.response ? salesChannel.response : []}
                        loading={salesChannel.loading}
                        field="acceptedCount" />
                </Card>
            </div>
        </div>
    )
}
