import React, { Fragment, useState } from 'react'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { STUDENT_MESSAGE } from '../urls'
import { useLoad, usePostRequest } from '../hooks/request'
import Input from './common/Input'
import { required } from '../utils/validators'
import Button from './common/Button'
import Table from './common/Table'


export default function StudentMessage({ student, onSuccess, customer }) {
    const [page, setPage] = useState(1)
    const initialValues = { text: '', student: '' }
    const transactionCreate = usePostRequest()

    const transactions = useLoad({
        url: STUDENT_MESSAGE.replace('{id}', student ? student.id : customer.id),
        params: { page, student: student ? student.id : undefined, customer: customer ? customer.id : undefined },
    }, [page])


    async function onSubmit(data, actions) {
        await transactionCreate.request({
            data,
            url: STUDENT_MESSAGE.replace('{id}', student ? student.id : customer.id),
        })
        actions.resetForm()
        onSuccess()
    }
    return (
        <Fragment>
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                <Form>
                    <b>Студент &nbsp; {student ? student.customer.name : customer.name}</b><br /><br />

                    <div className="columns">

                        <div className="column">
                            <Input
                                name="text"
                                placeholder="Введите сообщение"
                                validate={required} />
                        </div>


                        <div className="column is-narrow">
                            <Button
                                loading={transactionCreate.loading}
                                text="Добавить"
                                type="submit"
                                icon="ion-md-add"
                                className="is-info" />
                        </div>
                    </div>
                </Form>
            </Formik>

            <Table
                totalCount={transactions.response ? transactions.response.count : 0}
                columns={{
                    message: 'Сообщение',
                    createdAt: 'Дата создания',
                }}
                loading={transactions.loading}
                items={transactions.response ? transactions.response.results : []}
                onPageChange={setPage}
                activePage={page}
                renderItem={(transaction) => (
                    <tr key={transaction.id}>
                        <td>{transaction.text}</td>
                        <td>{moment(transaction.createdAt).calendar()}</td>
                    </tr>
                )} />
        </Fragment>
    )
}
