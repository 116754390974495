import React from 'react'
import Layout from '../components/Layout'
import WalletItem from '../components/WalletItem'
import { useLoad } from '../hooks/request'
import { WALLET_LIST } from '../urls'
import Loader from '../components/common/Loader'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'
import DateFilter from '../components/common/DateFilter'
import { useQueryParams } from '../hooks/queryString'

export default function Wallet() {
    const params = useQueryParams()
    const wallets = useLoad({ url: WALLET_LIST, params })

    if (!cp('finance.view_wallet')) {
        return <PermissionDenied />
    }

    return (
        <Layout className="has-background-light">
            <header style={{display:'flex', alignItems:'center', justifyContent:'space-between', gap:'1rem', flexWrap:'wrap', marginBottom:'1rem'}}>
                <span className="is-size-4">Счета</span><br /><br />

                <div style={{display:'flex', gap:'1rem'}}>
                    <DateFilter name='start_date' />
                    <DateFilter name='end_date' />
                </div>
            </header>

            <div className="columns is-multiline">
                { !wallets.loading && wallets.response ? wallets.response.results.map((wallet) => (
                    <WalletItem key={wallet.id} wallet={wallet} />
                )) : null}

                <Loader show={wallets.loading} large center />
            </div>
        </Layout>
    )
}
