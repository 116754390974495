import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Button from './common/Button'
import Input from './common/Input'

export default function RoomForm({ initialValues, onSubmit, loading, onCancel }) {
    return (
        <Formik initialValues={{ name: '', ...initialValues }} onSubmit={onSubmit}>
            <Form>
                <Input
                    label="Имя"
                    name="name"
                    disabled={loading}
                    placeholder="Введите имя кабинета"
                    validate={required} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
