import React from 'react'
import { format } from '../utils/number'
import { useLoad } from '../hooks/request'
import { EMPLOYEE_SALARY_HISTORY } from '../urls'
import { getDateTime, getMonth } from '../utils/date'

import Table from './common/Table'
import Button from './common/Button'


export default function EmployeeHistorySalary({ onCancel, employeeSalary, employeeDetail }) {
    const transactions = useLoad({
        url: EMPLOYEE_SALARY_HISTORY.replace('{id}', employeeDetail.id),
        params: { date: `${employeeSalary.month}-01` },
    })

    return (
        <div>
            <span className="is-size-4 ">
                Доход сотрудника за {getMonth(employeeSalary.month, true)}:&nbsp;
                <span className="has-text-success">{format(employeeSalary.profit || 0)} сум</span>
            </span>

            <br />
            <br />

            <table className="table is-fullwidth">
                <tbody>
                    <tr>
                        <td>Фиксированная зарпалата</td>
                        <td>{format(employeeDetail.salary)} сум</td>
                    </tr>
                </tbody>
            </table>

            <span className="is-size-5">Доля учителя ({employeeDetail.academyShare}%)</span>

            <Table
                loading={transactions.loading}
                items={transactions.response ? transactions.response.teacher : []}
                columns={{ customer: 'Студент', date: 'Дата', profit: 'Доход' }}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>
                            {item.student.customer.name}
                        </td>

                        <td>{getDateTime(item.createdAt)}</td>
                        <td>{format(item.profit)} Сум</td>
                    </tr>
                )} />

            <br />

            <span className="is-size-5">Доля Менеджера ({employeeDetail.sellerShare}%)</span>

            <Table
                loading={transactions.loading}
                items={transactions.response ? transactions.response.seller : []}
                columns={{ customer: 'Студент', date: 'Дата', profit: 'Доход' }}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>
                            {item.student.customer.name}
                        </td>

                        <td>{getDateTime(item.createdAt)}</td>
                        <td>{format(item.profit)} Сум</td>
                    </tr>
                )} />

            <br />

            <Button
                text="Закрыть"
                type="submit"
                onClick={onCancel}
                className="is-info" />
        </div>
    )
}
