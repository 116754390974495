import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Button from './common/Button'
import Input from './common/Input'

export default function AssessmentForm({ onSubmit, onCancel, loading }) {
    return (
        <Formik initialValues={{
            date: '',
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="date"
                    type="date"
                    label="Дата"
                    validate={required}
                />

                <Button
                    loading={loading}
                    text="Saqlash"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Bekor qilish"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
