import React from 'react'
import { STATUS_LIST } from '../urls'
import { usePostRequest } from '../hooks/request'
import ColumnForm from './ColumnForm'

export default function ColumnCreate({ onSuccess, onCancel }) {
    const statusCreate = usePostRequest({ url: STATUS_LIST })

    async function onSubmit(data, actions) {
        await statusCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <ColumnForm loading={statusCreate.loading} onCancel={onCancel} onSuccess={onSuccess} onSubmit={onSubmit} />
        </div>
    )
}
