import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../utils/auth'

export default function StatisticsTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                {cp('academy.view_advert_statistics') ? (
                    <li className={cn({ 'is-active': path.startsWith('/statistics/academy') })}>
                        <NavLink to="/statistics/academy">Академия</NavLink>
                    </li>
                ) : null}

                {cp('finance.view_transaction') ? (
                    <li className={cn({ 'is-active': path.startsWith('/statistics/finance') })}>
                        <NavLink to="/statistics/finance">Финансы</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
