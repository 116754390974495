import React from 'react'
import { NOTE_LIST } from '../urls'
import { usePostRequest } from '../hooks/request'
import NoteForm from './NoteForm'

export default function NoteCreate({ onSuccess, onCancel }) {
    const roomCreate = usePostRequest({ url: NOTE_LIST })

    async function onSubmit(data, actions) {
        await roomCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <NoteForm loading={roomCreate.loading} onCancel={onCancel} onSuccess={onSuccess} onSubmit={onSubmit} />
        </div>
    )
}
