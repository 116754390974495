import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { getDate } from '../utils/date'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import Card from './common/Card'
import LessonItemReasonUpdate from "./LessonItemReasonUpdate";

export default function LessonItem({ assessment, classId, onUpdate }) {
    const [showReasonModal, hideReasonModal] = useModal(
        <LessonItemReasonUpdate
            onUpdate={onUpdate}
            assessment={assessment}
            onCancel={()=> hideReasonModal()}
            initialValues={{comment:assessment?.comment || '', isActive: assessment?.isActive ? '0' : '1'}}
        />
    )
    return (
        <Card>
            <Link to={`/academy/class/${classId}/assessment/${assessment.id}`}>
                <div className="is-flex is-justify-content-center">
                    <div
                        className={cn('icon is-size-4', css(styles.icon), assessment?.isActive ? 'has-text-black' : 'has-text-grey-light')}>  {getDate(assessment.date)}
                    </div>
                </div>
            </Link>

            <hr style={{margin: '0 0 1rem 0'}}/>

            <main style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                <p>
                    Время: &nbsp;
                    <span className="has-text-info is-pulled-right">
                        21:00-22:00
                    </span>
                </p>

                <p>
                    Комната: &nbsp;
                    <span className="has-text-info is-pulled-right">
                        {assessment?.group?.room?.name}
                    </span>
                </p>

                <p style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    Статус: &nbsp;
                    <span className="is-pulled-right">
                        {assessment?.isActive ?
                            (<span className="tag is-light is-success">
                                Состоялся
                            </span>) :
                            (<span className="is-light is-danger tag">
                                Не состоялся
                            </span>)
                        }
                    </span>

                    <Button
                        onClick={showReasonModal}
                        className={'is-size-7'}
                        icon={'ion-md-create'}
                    />
                </p>

                <p>
                    Обновлено кем: &nbsp;

                    {assessment.updatedBy ?
                        <span className="is-pulled-right">
                        {assessment.updatedBy.firstName} {assessment.updatedBy.lastName}
                    </span> : <span className="is-pulled-right">
                            не обновлен
                    </span>
                    }

                </p>

                <p>
                    Обновленное время: &nbsp;
                    <span className="has-text-info is-pulled-right">
                        {getDate(assessment?.updatedAt)}
                    </span>
                </p>
            </main>
        </Card>
    )
}

const styles = StyleSheet.create({
    icon: {
        width: '100%',
        fontSize: '2em',
        margin: '2rem 1rem',
    },
    height: {
        height: '100%',
    },
    buttonAction: {
        '@media (max-width: 500px)': {
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
        },
    },
})
