import { Form, Formik } from 'formik'
import React from 'react'
import { required, validator, minTime } from '../utils/validators'
import Input from './common/Input'
import Button from './common/Button'
import WeekDayPicker from './common/WeekDayPicker'


export default function GroupTimelineForm({ onSubmit, onCancel, loading, initialValues }) {
    const values = {
        startTime: '12:00',
        endTime: '13:00',
        days: [],
        ...initialValues,
    }

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            {({ values: { startTime } }) => (
                <Form>
                    <WeekDayPicker label="Расписание занятий" name="days" />

                    <div className="columns">
                        <div className="column is-narrow">
                            <Input label="C" validate={required} name="startTime" type="time" step="600" />
                        </div>

                        <div className="column is-narrow">
                            <Input
                                label="По"
                                validate={validator(required, minTime(startTime))}
                                name="endTime"
                                type="time"
                                step="600" />
                        </div>
                    </div>

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
