import React from 'react'
import { usePutRequest } from '../hooks/request'
import { STUDENT_DETAIL } from '../urls'
import CustomerInfoGroupsForm from './CustomerInfoGroupsForm'

export default function CustomerInfoGroupsUpdate({ onCancel, onUpdate, item, customer, onSuccess }) {
    const url = STUDENT_DETAIL.replace('{id}', item.id)
    const studentUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success, response } = await studentUpdate.request({ data: { ...data, customer: customer.id } })
        // const newCustomer = {
        //     ...customer,
        //     students: customer.students.map((student) => (
        //         student.id === response.id ? response : student
        //     )),
        // }

        if (success) {
            onSuccess()
            actions.resetForm()
            // onUpdate(newCustomer.id, newCustomer)
        }
    }

    return (
        <div>
            <b>Добавить нового студента</b><br /><br />

            <CustomerInfoGroupsForm
                customer={customer}
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={item}
                loading={studentUpdate.loading} />
        </div>
    )
}
