import React from 'react'
import { ASSESSMENT_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import LessonItemReasonForm from './LessonItemReasonForm'

export default function LessonItemReasonUpdate({ onCancel, assessment, onUpdate }) {
    const roomUpdate = usePutRequest({ url: ASSESSMENT_DETAIL.replace('{id}', assessment.id) })

    async function onSubmit(data, actions) {
        const { success } = await roomUpdate.request({
            data: {
                ...data,
                group: data.group.id,
                teacher: data.teacher.id,
            },
        })
        if (success) {
            actions.resetForm()
            onUpdate()
            onCancel()
        }
    }

    return (
        <div>
            <LessonItemReasonForm
                loading={roomUpdate.loading}
                onCancel={onCancel}
                initialValues={assessment}
                onSubmit={onSubmit} />
        </div>
    )
}
