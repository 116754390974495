import cn from 'classnames'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Draggable } from 'react-beautiful-dnd'
import { NavLink } from 'react-router-dom'
import { useModal } from '../hooks/modal'
import CustomerActions from './CustomerActions'
import CustomerUpdate from './CustomerUpdate'
import CustomerInfo from './CustomerInfo'
import { tags } from './CustomerType'
import CustomerGroups from './CustomerGroups'


export default function CustomerBoardCard({
    customer,
    index,
    onUpdate,
    showLabels,
    type,
    reload,
}) {
    const [showUpdateModal, hideUpdateModal] = useModal(
        <CustomerUpdate
            customer={customer}
            onCancel={() => hideUpdateModal()}
            onSuccess={(values) => {
                onUpdate(customer.id, values)
                hideUpdateModal()
            }} />,
    )

    // const [showInfoModal, hideInfoModal] = useModal(
    //     <CustomerInfo
    //         onCancel={() => hideInfoModal()}
    //         onChange={() => {
    //             hideInfoModal()
    //             showUpdateModal()
    //         }}
    //         customer={customer}
    //         onUpdate={(newCustomerId, newCustomer) => {
    //             onUpdate(newCustomerId, newCustomer)
    //             hideInfoModal()
    //         }}
    //         onDelete={(newCustomerId, newCustomer) => {
    //             onUpdate(newCustomerId, newCustomer)
    //             hideInfoModal()
    //         }} />,
    // )

    return (
        <Draggable draggableId={String(customer.id)} index={index}>
            {(provider, snap) => (
                <div
                    // onClick={showInfoModal}
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    className={cn('box', css(styles.card, snap.isDragging && styles.active))}
                    style={provider.draggableProps.style}>

                    <main style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
                        <p style={{ alignSelf: 'center' }} className={css(styles.nameCustomer)}>
                            <span
                                style={{ marginRight: 10 }}
                                className="tag is-small is-link is-light">
                                {index + 1}
                            </span>
                            <NavLink style={{ whiteSpace: 'wrap' }} to={`/academy/customers/${customer.id}/`}>
                                <span>{customer.name}</span>
                            </NavLink>
                        </p>

                        <CustomerActions
                            customer={customer}
                            onClickUpdate={showUpdateModal}
                            className={css(styles.actions)}
                            onUpdate={onUpdate}
                            paramsType={type}
                            reload={reload}
                        />
                    </main>

                    <CustomerGroups customer={customer} />

                    {showLabels ? tags[customer.type] : null}
                </div>
            )}
        </Draggable>
    )
}

const styles = StyleSheet.create({
    card: {
        userSelect: 'none',
        marginBottom: '0.5rem',
        borderRadius: '3px',
        whiteSpace: 'nowrap',
        paddingRight: 0,
        paddingTop: '0.75rem',
    },
    nameCustomer: {
        gap: 10,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        '@media (max-width: 769px)': {
            maxWidth: '30rem',
        },
    },
    actions: {
    },
    active: {
        // drugging styles goes here
    },
})
