import React from 'react'
import map from 'lodash/map'
import { EMPLOYEE_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import { position } from '../utils/position'
import EmployeeForm from './EmployeeForm'
import ServerError from './common/ServerError'

export default function EmployeeUpdate({ onCancel, onUpdate, employee }) {
    const url = EMPLOYEE_DETAIL.replace('{id}', employee.id)
    const employeeUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await employeeUpdate.request({ data: {
            ...data, positions: map(data.positions, 'value'),
        } })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    const initialValues = {
        ...employee,
        positions: employee.positions.map((item) => ({ label: position(item), value: item })),
    }

    return (
        <div>
            <b>Редактировать Сотрудника</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <EmployeeForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
