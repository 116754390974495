import React from 'react'
import { Form, Formik } from 'formik'
import Select from './common/Select'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'

const options = [{ id: '0', name: 'Состоялся' }, { id: '1', name: 'Не состоялся' }]

export default function LessonItemReasonForm({ onCancel, initialValues, onSubmit }) {
    const handleSubmit = (vals, actions) => {
        const data = {
            ...vals,
            isActive: vals.isActive === '0' ? true : false
        }
        onSubmit(data, actions)
    }
    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    ...initialValues,
                    isActive: initialValues?.isActive ? '0' : '1' ,
                    comment: initialValues?.comment || '',
                }}
                onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                    <Form>
                        <Select
                            name="isActive"
                            label="Статус урока"
                            validate={required}
                            options={options}
                            onChange={(e) => {
                                setFieldValue('isActive', e.target.value)
                                setFieldValue('comment', '')
                            }}
                            // disabled={loading}
                        />
                        {values.isActive === '1'
                            && (
                                <Input
                                    name="comment"
                                    label="Причина"
                                    component="textarea"
                                    validate={required}
                                // disabled={loading}
                                />
                            )}
                        <div className="is-pulled-right">
                            <Button
                                // loading={loading}
                                // disabled={loading}
                                text="Сохранить"
                                type="submit"
                                icon="ion-md-checkmark"
                                className="is-success" /> &nbsp;

                            <Button
                                onClick={onCancel}
                                // disabled={loading}
                                icon="ion-md-close"
                                text="Отмена"
                                className="is-danger" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
