/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */


import React from 'react';
import { NavLink } from "react-router-dom";
import cn from 'classnames'

export default function TimeLineItem({ item }) {
    
    return (
        <tr>
            <td>
                <b>{item?.name}</b>
            </td>
            {['1_monday', '2_tuesday', '3_wednesday', '4_thursday', '5_friday', '6_saturday', '7_sunday'].map(day => {
                // Get all lessons for the current day
                const lessonsForDay = item?.groups.reduce((acc, group) => {
                    const lessons = group.timelines.filter(timeline => timeline.day === day);
                    return [...acc, ...lessons];
                }, []);

                return (
                    <td key={day}>
                        {lessonsForDay.length > 0 ? (
                            <div>
                                {lessonsForDay.map((lesson, idx) => {
                                    const groupWithTimeline = item?.groups.find(group => group.id === lesson.group);
                                    return (
                                        <NavLink key={lesson.id} to={`/academy/group/${lesson.group}/students-list`}>
                                            <div style={{ marginBottom: 10 }} className={cn("tag is-light", idx === 0 ? 'is-link' : idx === 1 ? 'is-primary' : idx === 2 ? 'is-info' : idx === 3 ? 'is-success' : idx === 4 ? 'is-warning' : 'is-primary')}>
                                                {groupWithTimeline?.name}: {lesson?.startTime?.slice(0, 5) ?? ''} - {lesson?.endTime?.slice(0, 5) ?? ''}
                                            </div>
                                        </NavLink>
                                    );
                                })}
                            </div>
                        ) : null}
                    </td>
                );
            })}
        </tr>
    );
}

