import React from 'react'
import { GROUPS_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import GroupForm from './GroupForm'

export default function GroupUpdate({ onCancel, onUpdate, group }) {
    const url = GROUPS_DETAIL.replace('{id}', group.id)
    const groupUpdate = usePutRequest({ url })
    const values = {
        ...group,
        teacher: group.teacher.id,
        subject: group.subject.id,
        room: group.room.id,
    }

    async function onSubmit(data, actions) {
        await groupUpdate.request({ data })
        actions.resetForm()
        onUpdate()
    }

    return (
        <div>
            <b>Изменить группу</b><br /><br />
            <GroupForm
                initialValues={values}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={groupUpdate.loading} />
        </div>
    )
}
