import React from 'react'
import { usePostRequest } from '../hooks/request'
import { GROUPS_TIMELINE_SYNC } from '../urls'
import GroupTimelineForm from './GroupTimelineForm'

export default function GroupTimelineSync({ onCancel, onSuccess, group }) {
    const timelineSync = usePostRequest({ url: GROUPS_TIMELINE_SYNC })

    const days = group.timelines.map((timeline) => timeline.day)

    const values = {
        group: group.id,
        days,
        startTime: group.timelines[0] ? group.timelines[0].startTime : '12:00',
        endTime: group.timelines[0] ? group.timelines[0].endTime : '13:00',
    }

    async function onSubmit(data, actions) {
        await timelineSync.request({
            data: {
                ...data,
                group: group.id,
                timelines: data.days.map((day) => ({
                    day,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    group: data.group,
                })),
            },
        })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Выберите учебного времени</b><br /><br />

            <GroupTimelineForm
                onSubmit={onSubmit}
                initialValues={values}
                onCancel={onCancel}
                loading={timelineSync.loading} />
        </div>
    )
}
