import React from 'react'
import { NOTE_ITEM_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import NoteItemForm from './NoteItemForm'

export default function NoteItemUpdate({ onSuccess, onCancel, item }) {
    const roomUpdate = usePutRequest({ url: NOTE_ITEM_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data, actions) {
        await roomUpdate.request({ data })
        actions.resetForm()
        onSuccess()
    }
    return (
        <div>
            <NoteItemForm
                loading={roomUpdate.loading}
                onCancel={onCancel}
                initialValues={{employee:item?.employee?.id ?? '', note: item?.note?.id ?? '', comment:item?.comment ?? ''}}
                onSuccess={onSuccess}
                onSubmit={onSubmit} />
        </div>
    )
}
