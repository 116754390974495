import React from 'react'
import { HOLIDAY_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import HolidayForm from './HolidayForm'

export default function HolidayUpdate({ onSuccess, onCancel, holiday }) {
    const roomUpdate = usePutRequest({ url: HOLIDAY_DETAIL.replace('{id}', holiday.id) })

    async function onSubmit(data, actions) {
        await roomUpdate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <HolidayForm
                loading={roomUpdate.loading}
                onCancel={onCancel}
                initialValues={holiday}
                onSuccess={onSuccess}
                onSubmit={onSubmit} />
        </div>
    )
}
