import React, { Fragment, useState } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { format } from '../utils/number'
import { PAYMENT_STUDENT, WALLET_SIMPLE_LIST } from '../urls'
import Select from './common/Select'
import { useLoad, usePostRequest } from '../hooks/request'
import Input from './common/Input'
import { required } from '../utils/validators'
import Button from './common/Button'
import Table from './common/Table'


export default function StudentPayment({ student, onSuccess, customer }) {
    const [page, setPage] = useState(1)
    const initialValues = { amount: '', wallet: '', student: '' }
    const transactionCreate = usePostRequest()
    const wallets = useLoad({ url: WALLET_SIMPLE_LIST })

    const transactions = useLoad({
        url: PAYMENT_STUDENT.replace('{id}', student ? student.id : customer.id),
        params: { page, student: student ? student.id : undefined, customer: customer ? customer.id : undefined },
    }, [page])


    async function onSubmit(data, actions) {
        await transactionCreate.request({
            data,
            url: PAYMENT_STUDENT.replace('{id}', student ? student.id : data.student),
        })
        actions.resetForm()
        onSuccess()
    }

    return (
        <Fragment>
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                <Form>
                    <b>Студент &nbsp; {student ? student.customer.name : customer.name}</b><br /><br />

                    <div className="columns">
                        {customer ? (
                            <div className="column">
                                <Select
                                    name="student"
                                    options={customer.students}
                                    optionLabel={(item) => item.group.name}
                                    validate={required} />
                            </div>
                        ) : null}

                        <div className="column">
                            <Input
                                name="amount"
                                type="number"
                                placeholder="Сумма"
                                validate={required} />
                        </div>

                        <div className="column">
                            <Select
                                name="wallet"
                                help="Вы можете добавить новый счёт в настройках"
                                options={wallets.response || []}
                                loading={wallets.loading}
                                validate={required} />
                        </div>

                        <div className="column is-narrow">
                            <Button
                                loading={transactionCreate.loading}
                                text="Добавить"
                                type="submit"
                                icon="ion-md-add"
                                className="is-info" />
                        </div>
                    </div>
                </Form>
            </Formik>

            <Table
                totalCount={transactions.response ? transactions.response.count : 0}
                columns={{
                    amount: 'Сумма',
                    wallet: 'Счет',
                    group: 'Группа',
                    createdAt: 'Дата создания',
                }}
                loading={transactions.loading}
                items={transactions.response ? transactions.response.results : []}
                onPageChange={setPage}
                activePage={page}
                renderItem={(transaction) => (
                    <tr key={transaction.id}>
                        <td className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>
                            {format(transaction.amount)}
                        </td>

                        <td>{transaction.wallet.name}</td>
                        <td>{transaction.student.group.name}</td>
                        <td>{moment(transaction.createdAt).calendar()}</td>
                    </tr>
                )} />
        </Fragment>
    )
}
