import React from 'react'
import Layout from '../components/Layout'
import { useModal } from '../hooks/modal'
import { PermissionDenied } from '../components/PermissionDenied'
import Button from '../components/common/Button'
import { useLoad } from '../hooks/request'
import { BRANCH_LIST } from '../urls'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import { checkPermission as cp } from '../utils/auth'
import BranchItem from '../components/BranchItem'
import BranchCreate from '../components/BranchCreate'

export default function Branch() {
    const params = useQueryParams()
    const tasks = useLoad({ url: BRANCH_LIST, params })

    const [showCreateModal, hideCreateModal] = useModal(
        <BranchCreate onSuccess={() => {
            hideCreateModal()
            tasks.request()
        }} onCancel={() => hideCreateModal()} />,
    )

    if (!cp('task.view_task')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <span className="is-size-4">Филиалы</span>&nbsp; &nbsp;

            {cp('task.add_task') ? (
                <Button
                    onClick={showCreateModal}
                    text="Добавить"
                    icon="ion-md-add"
                    className="is-pulled-right is-link is-outlined" />
            ) : null}

            <br />
            <br />

            {/* <TaskFilter /><br /> */}

            {tasks.response ? (
                <Table
                    loading={tasks.loading}
                    // totalCount={tasks.response ? tasks.response : 0}
                    columns={{
                        title: 'Название филиала',
                        assigned: 'Количество студентов',
                        dueTime: 'Количество учителей',
                        actions: '',
                    }}
                    items={tasks.response ? tasks.response : []}
                    renderItem={(item) => (
                        <BranchItem onDelete={tasks.request} onUpdate={tasks.request} key={item.id} item={item} />
                    )} />
            ) : null}

            <br />
        </Layout>
    )
}
