import React, { useState } from 'react'
import cn from 'classnames'
import { format } from '../utils/number'
import { useModal } from '../hooks/modal'
import { checkForAdmin, checkPermission as cp } from '../utils/auth'
import StudentPayment from './StudentPayment'
import StudentInfo from './StudentInfo'
import Loader from './common/Loader'
import { usePutRequest } from '../hooks/request'
import { STUDENT_DETAIL } from '../urls'


export default function StudentItem({ item, onPaymentCreate, onSuccess, index }) {
    const isAdmin = checkForAdmin()
    const studentUpdate = usePutRequest({ url: STUDENT_DETAIL.replace('{id}', item.id) })
    const [showDropdown, setShowDropdown] = useState(false)
    const [showPaymentModal, hidePaymentModal] = useModal(
        <StudentPayment
            onSuccess={() => {
                hidePaymentModal()
                onPaymentCreate()
            }}
            student={item} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <StudentInfo
            onCancel={() => hideInfoModal()}
            customer={item.customer} />,
    )

    async function onStatusChange() {
        const data = { ...item, isActive: !item.isActive, group: item.group.id, customer: item.customer.id }
        const { success } = await studentUpdate.request({ data })
        if (success) {
            onSuccess()
            window.location.reload()
        }
    }

    return (
        <tr className={cn({ 'has-text-grey': !item.isActive })}>
            <td>{index + 1}</td>
            <td className="pointer" onClick={showInfoModal}>{item.customer.name}</td>
            <td className="pointer">{item.customer.phone}</td>
            <td className="pointer">{format(item.totalPaid || 0)}</td>
            <td className="pointer">{format(item.monthPaid || 0)}</td>
            <td className="pointer">{format(item.balance || 0)}</td>

            <td>
                {isAdmin && (
                    <div
                    className={cn('dropdown is-right', { 'is-active': showDropdown })}
                    onMouseLeave={() => setShowDropdown(false)}>
                        <div className="dropdown-trigger pointer">
                            <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                        </div>

                        <div className="dropdown-menu">
                            <div className="dropdown-content">
                                {cp('finance.add_student_payment') ? (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a className="pointer dropdown-item" onClick={showPaymentModal}>
                                        <i className="icon ion-md-cash" />
                                        Оплата
                                    </a>
                                ) : null}

                                {!studentUpdate.loading ? (
                                    <span>
                                        {cp('finance.change_wallet') && !item.isActive ? (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a className="dropdown-item" onClick={onStatusChange}>
                                                <i className="icon ion-md-checkmark" />
                                                Не активным
                                            </a>
                                        ) : (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a onClick={onStatusChange} className="dropdown-item">
                                                <i className="icon ion-md-checkmark" />
                                                Активный
                                            </a>
                                        )}
                                    </span>
                                ) : <Loader center />}
                            </div>
                        </div>
                    </div>
                )}
            </td>
        </tr>
    )
}
