import React from 'react'
import cn from 'classnames'
import { useModal } from '../hooks/modal'
import StudentMessageList from './StudentMessageList'


export default function StudentMessageItem({ item, index, checkedItems, setCheckedItems }) {
    const [showInfoModal, hideInfoModal] = useModal(
        <StudentMessageList
            onCancel={() => hideInfoModal()}
            customer={item} />,
    )
    const isChecked = !!checkedItems.find(checkedItem => checkedItem.id === item.id )

    return (
        <tr className={cn({ 'has-text-grey': item.isActive })}>
            <td>
                <input
                    type="checkbox" 
                    onChange={(e => {
                        const checked = e.target.checked 
                        if(checked){
                            setCheckedItems(prev => ([...prev, item]))
                        } else {
                            setCheckedItems(prev => prev.filter(prevItem => prevItem.id !== item.id))
                        }
                    })} 
                    checked={isChecked}
                />
            </td>
            <td>{index + 1}</td>
            <td className="pointer" onClick={showInfoModal}>{item.name}</td>
            <td>{item.phone} {item.phoneSecond
                ? <span>, {item.phoneSecond}</span> : null}{item.phoneThird
                ? <span>, {item.phoneThird}</span> : null}
            </td>
            <td className="pointer">{item.students.map((student) => (
                <span key={student?.id}>{student.group.name}  </span>
            ))}
            </td>
        </tr>
    )
}
