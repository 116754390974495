import React from 'react'
import { usePostRequest } from '../hooks/request'
import { BRANCH_LIST } from '../urls'
import BranchForm from './BranchForm'

export default function BranchCreate({ onCancel, onSuccess }) {
    const taskCreate = usePostRequest({ url: BRANCH_LIST })

    async function onSubmit(data, actions) {
        await taskCreate.request({
            data: {
                ...data,
                dueTime: data.dueTime ? data.dueTime : null,
            },
        })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Добавить новую ветку</b><br /><br />

            <BranchForm
                loading={taskCreate.loading}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
