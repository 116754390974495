import React from 'react'
import AcademyTabs from './AcademyTabs'
import SearchFilter from './common/SearchFilter'

export default function AcademyHeader() {
  return (
    <header style={{
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap',
        columnGap: '2rem',
        marginBottom: '1rem',
        rowGap: '1rem',
    }}>
        <AcademyTabs />
        <SearchFilter />
    </header>
  )
}
