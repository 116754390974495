import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import Layout from '../components/Layout'
import { useModal } from '../hooks/modal'
import { PermissionDenied } from '../components/PermissionDenied'
import Button from '../components/common/Button'
import { useLoad } from '../hooks/request'
import { NOTE_ITEM_LIST } from '../urls'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import { checkPermission as cp } from '../utils/auth'
import NoteItemCreate from '../components/NoteItemCreate'
import NoteItemItem from '../components/NoteItemItem'
import FilterByEmployee from "../components/common/FilterByEmployee";
import DateFilter from '../components/common/DateFilter'
import AcademyHeader from '../components/AcademyHeader'

export default function EmployeeNotes() {
    const params = useQueryParams()
    const tasks = useLoad({ url: NOTE_ITEM_LIST, params })
    const resultArchive = (tasks.response ? tasks.response.results : []).reduce((total, currentValue) => total + parseInt(currentValue?.note.price, 10), 0)
    const [showCreateModal, hideCreateModal] = useModal(
        <NoteItemCreate onSuccess={() => {
            hideCreateModal()
            tasks.request()
        }} onCancel={() => hideCreateModal()} />,
    )

    if (!cp('task.view_task')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <AcademyHeader />

            <main className={cn(css(styles.heading))}>
                    <span className="is-size-4">Сумма всех штрафов: {resultArchive}</span>

                {cp('task.add_task') ? (

                    <div style={{ gap: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex:'auto', flexWrap:'wrap' }}>
                        <FilterByEmployee/>
                        <div style={{display:'flex', gap:'1rem'}}>
                            <DateFilter name='start_date'/>
                            <DateFilter name='end_date'/>
                        </div>
                        <Button
                            style={{ marginLeft: 10 }}
                            onClick={showCreateModal}
                            text="Добавить"
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined ml-5"/>
                    </div>

                ) : null}
            </main>

            {/* <TaskFilter /><br /> */}

            {tasks.response ? (
                <Table
                    loading={tasks.loading}
                    // totalCount={tasks.response ? tasks.response : 0}
                    columns={{
                        index: '',
                        title: 'Имя сотрудника',
                        assigned: 'Отметка',
                        dueTime: 'Штрафная сумма',
                        comment: 'Комментарий',
                        createdAt: 'Создан в',
                        actions: '',
                    }}
                    items={tasks.response ? tasks.response.results : []}
                    renderItem={(item, index) => (
                        <NoteItemItem index={index} onDelete={tasks.request} onUpdate={tasks.request} key={item.id}
                                      item={item}/>
                    )} />
            ) : null}

            <br />
        </Layout>
    )
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: '1rem',
        display:'flex', 
        gap:'1rem',
        flexWrap:'wrap'
    },
})
