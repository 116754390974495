import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_SALES_FUNNEL } from '../urls'
import StatisticSalesFunnelProgress from './StatisticSalesFunnelProgress'
import Card from './common/Card'


export default function StatisticsSalesFunnel({ startDate, endDate }) {
    const salesFunnel = useLoad({
        url: STATISTICS_SALES_FUNNEL,
        params: { startDate, endDate },
    })

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                Воронка продаж
            </div>

            <StatisticSalesFunnelProgress
                salesFunnel={salesFunnel.response || []}
                loading={salesFunnel.loading}
                field="count" />
        </Card>
    )
}
