import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import cn from 'classnames'
import { getEnv } from '../utils/environment'


export default function LayoutAuth({ children, sidebar }) {
    return (
        <div className="login-wrapper columns is-gapless">
            <div className="column is-7">
                <div className="hero form-hero is-fullheight">
                    <div className="hero-body has-text-centered">
                        <div className={css(styles.formWrapper)}>
                            <div className={css(styles.container)}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cn(
                'column is-5 is-hidden-mobile hero-banner',
                css(styles.sidebar,
                    getEnv() === 'development' ? styles.developmentSidebar : null,
                    getEnv() === 'staging' ? styles.stagingSidebar : null),
            )}>
                <div className="hero is-fullheight">
                    <div className="hero-body">
                        <div className="container has-text-centered has-text-white">
                            <div className="columns">
                                <div className="column is-10 is-offset-1">
                                    {sidebar}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '1.25rem',
    },
    roundedButtons: {
        '> button': {
            borderRadius: '50%',
        },
    },
    input: {
        color: '#4a4a4a',
    },
    isPrimary: {
        backgroundColor: 'rgb(31, 38, 104) !important',
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#0062ff',
        },
    },
    sidebar: {
        backgroundColor: 'rgb(31, 38, 104) !important',
    },
    developmentSidebar: {
        background: 'RGBA(31, 38, 104)',
    },
    stagingSidebar: {
        background: 'RGBA(31, 38, 104)',
    },
    hasTextPrimary: {
        color: 'rgb(31, 38, 104) !important',
    },
    formWrapper: {
        maxWidth: '540px',
        minWidth: '380px',
        margin: '0 auto',
    },
})
