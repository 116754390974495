import React from 'react'
import map from 'lodash/map'
import { CUSTOMER_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import CustomerForm from './CustomerForm'

export default function CustomerUpdate({ onCancel, onSuccess, customer }) {
    const url = CUSTOMER_DETAIL.replace('{id}', customer.id)
    const customerUpdate = usePutRequest({ url })

    async function onSubmit(values) {
        const { response, success } = await customerUpdate.request({ data: {
            ...values,
            status: String(customer.status),
        } })

        if (success) {
            onSuccess(response)
        }
    }

    const initialValues = {
        ...customer,
        seller: customer.seller.id,
    }
    return (
        <div>
            <b>Изменить Студента</b><br /><br />

            <CustomerForm
                values={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={customerUpdate.loading} />
        </div>
    )
}
