import React from 'react'
import map from 'lodash/map'
import { usePostRequest } from '../hooks/request'
import { CUSTOMER_LIST } from '../urls'
import { integersOnly } from '../utils/number'
import CustomerForm from './CustomerForm'

export default function CustomerCreate({ state, onCancel, onSuccess, initialValues }) {
    const customerCreate = usePostRequest({ url: CUSTOMER_LIST })

    async function onSubmit(values, actions) {
        // let phoneSecond = values.phoneSecond ? integersOnly(values.phoneSecond) : null
        // phoneSecond = phoneSecond === integersOnly(values.phone) ? null : phoneSecond
        // let phoneThird = values.phoneThird ? integersOnly(values.phoneThird) : null
        // phoneThird = phoneThird === integersOnly(values.phone) ? null : phoneThird

        const data = {
            ...values,
            state,
        }
        const {success, response} = await customerCreate.request({ data })
        if(success){
            actions.resetForm()
            onSuccess(response)
        }
    }
    return (
        <div>
            <b>Добавить нового студента</b><br /><br />
                
            <CustomerForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                values={initialValues}
                loading={customerCreate.loading} />
        </div>
    )
}
