import React from 'react'
import { ALL_MESSAGE } from '../urls'
import { usePostRequest } from '../hooks/request'
import MessageForm from './MessageForm'
import ServerError from './common/ServerError'

export default function MessageCreate({ onSuccess, onCancel, groupId, type }) {
    const roomCreate = usePostRequest({ url: ALL_MESSAGE })

    async function onSubmit(data, actions) {
        await roomCreate.request({ data: { ...data, group: groupId, type } })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <ServerError error={roomCreate.error} />
            <MessageForm
                loading={roomCreate.loading}
                onCancel={onCancel} onSuccess={onSuccess}
                onSubmit={onSubmit} />
        </div>
    )
}
