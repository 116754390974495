import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../utils/auth'

export default function AcademyTabs() {
    const { path } = useRouteMatch()
    return (
        <div className="tabs" style={{ marginBottom: 0 }}>
            <ul>
                {cp('academy.view_customer') ? (
                    <li className={cn({ 'is-active': path.startsWith('/academy/customers') })}>
                        <NavLink to="/academy/customers">Клиенты</NavLink>
                    </li>
                ) : null}
                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/academy/group') })}>
                        <NavLink to="/academy/group">Группы</NavLink>
                    </li>
                ) : null}
                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/academy/timeline') })}>
                        <NavLink to="/academy/timeline">Расписание</NavLink>
                    </li>
                ) : null}
                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/academy/message') })}>
                        <NavLink to="/academy/message">Сообщения</NavLink>
                    </li>
                ) : null}
                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/academy/note') })}>
                        <NavLink to="/academy/note">Этика</NavLink>
                    </li>
                ) : null}
                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/academy/debtors') })}>
                        <NavLink to="/academy/debtors">Должники</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
