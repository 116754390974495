import React from 'react'
import { STATUS_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ColumnForm from './ColumnForm'

export default function ColumnUpdate({ onSuccess, onCancel, status }) {
    const statusUpdate = usePutRequest({ url: STATUS_DETAIL.replace('{id}', status.id) })

    async function onSubmit(data, actions) {
        await statusUpdate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <ColumnForm
                loading={statusUpdate.loading}
                onCancel={onCancel}
                initialValues={status}
                onSuccess={onSuccess}
                onSubmit={onSubmit} />
        </div>
    )
}
