import React from 'react'
import { ASSESSMENT_LIST } from '../urls'
import ServerErrorCustom from './common/ServerErrorCustom'
import { usePostRequest } from '../hooks/request'
import AssessmentForm from './AssessmentForm'

export default function AssessmentCreate({ onCancel, onSuccess, groupId, groupName, teacherId }) {
    const assessmentCreate = usePostRequest({ url: ASSESSMENT_LIST })

    async function onSubmit(data, actions) {
        const { success } = await assessmentCreate.request({
            data: {
                ...data,
                group: groupId,
                teacher: teacherId,
            },
        })
        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавьте новый урок в {groupName}</b><br /><br />
            <ServerErrorCustom error={assessmentCreate.error} />
            <AssessmentForm onSubmit={onSubmit} onCancel={onCancel} loading={assessmentCreate.loading} />
        </div>
    )
}
