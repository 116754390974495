import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Button from './common/Button'
import Input from './common/Input'
import ColorPicker from './common/ColorPicker'

export default function ColumnForm({ initialValues, onSubmit, loading, onCancel }) {
    return (
        <Formik initialValues={{ title: '', color: '#3273dc', ...initialValues }} onSubmit={onSubmit}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input
                            label="Заголовок"
                            name="title"
                            disabled={loading}
                            validate={required}
                            placeholder="Введите заголовок колонки" />

                    </div>

                    <div className="column">
                        <ColorPicker
                            label="Цвет"
                            validate={required}
                            name="color" />
                    </div>
                </div>


                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
