import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import moment from 'moment'
import { StyleSheet, css } from 'aphrodite'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import { useInfiniteScroll, useLoad } from '../hooks/request'
import { useModal } from '../hooks/modal'
import AssessmentCreate from '../components/AssessmentCreate'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import { ASSESSMENT_LIST, GROUPS_DETAIL } from '../urls'
import LessonItem from '../components/LessonItem'
import Loader from '../components/common/Loader'
import DateFilter from '../components/common/DateFilter'

export default function GroupDetail() {
    const { classId } = useParams()
    const params = useQueryParams()
    const classDetail = useLoad({ url: GROUPS_DETAIL.replace('{id}', classId) })
    const [page, setPage] = useState(1)
    const date = params.date || moment().subtract(0, 'months').format(DATE_FORMAT)
    const assessments = useInfiniteScroll({ url: ASSESSMENT_LIST, params: { group: classId, page, date, startDate: params.startDate, endDate: params.endDate } }, [page])
    const className = classDetail.response
    const [showCreateModal, hideCreateModal] = useModal(
        <AssessmentCreate
            groupName={className ? className.name : ''}
            teacherId={className ? className.teacher?.id : ''}
            groupId={classId}
            onSuccess={() => {
                assessments.request()
                hideCreateModal()
            }} onCancel={() => hideCreateModal()} />,
    )

    return (
        <Layout>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap:'1rem' }}>
                <div className="mb-2">
                    <NavLink style={{marginRight:'1rem'}} className="button is-white" to="/academy/group">
                        <i className="icon has-text-link ion-md-arrow-back is-size-4" />
                    </NavLink>

                    <span className="is-size-4">
                        Курс : {className ? className.name : ''}
                    </span>
                </div>


                <div className="mb-2" style={{ flex: 'auto', display:'flex', alignItems:'center', gap:'1rem', justifyContent:'flex-end', flexWrap:'wrap' }}>
                    <div style={{display:'flex', gap:'1rem'}}>
                        <DateFilter name='startDate' />
                        <DateFilter name='endDate' /> 
                    </div>
                    <Button
                        onClick={showCreateModal}
                        text="Добавить"
                        icon="ion-md-add"
                        className="is-link is-outlined" />
                </div>


            </div>

            <br />
            <div className={css(styles.cards)}>
                {assessments.response ? assessments.response.results.map((assessment, index) => (
                    <LessonItem
                        key={assessment?.id}
                        index={index}
                        onDelete={assessments.request}
                        onUpdate={assessments.request}
                        assessment={assessment}
                        classId={classId} />
                )) : null}
                <Loader show={assessments.loading} large center />
            </div>
            <div ref={assessments.ref} className="has-text-grey-light is-italic has-text-centered" style={{marginTop:'2rem'}}>
                {!assessments.hasMore && !assessments.loading && assessments.length !== 0 ? 'Все уроки загружены' : ''}
            </div>

        </Layout>
    )
}

const styles = StyleSheet.create({
    assessment: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    assessmentTable: {
        '@media (max-width: 500px)': {
            width: '800px',
        },
    },
    cards: {
        display:'grid',
        gridTemplateColumns:'repeat(auto-fill, minmax(340px, 1fr))',
        gap:'1rem'
    }
})
