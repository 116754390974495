import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import Layout from '../components/Layout'
import StatisticsAcademySalesChannel from '../components/StatisticsAcademySalesChannel'
import DateRange from '../components/common/DateRange'
import { useQueryParams } from '../hooks/queryString'
import { DATE_FORMAT } from '../utils/date'
import StatisticsTabs from '../components/StatisticsTabs'
import StatisticsAcademyCostAdvert from '../components/StatisticsAcademyCostAdvert'
import Card from '../components/common/Card'
import StatisticsSalesFunnel from '../components/StatisticsSalesFunnel'


export default function StatisticsAcademy() {
    const history = useHistory()
    const params = useQueryParams()
    const startDate = params.startDate || moment().subtract(1, 'months').format(DATE_FORMAT)
    const endDate = params.endDate || moment().format(DATE_FORMAT)

    return (
        <Layout className="has-background-light">
            <StatisticsTabs />

            <DateRange
                startDate={startDate}
                endDate={endDate}
                onDatesChange={(dates) => {
                    history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                }} />
            
            <br />

            <StatisticsAcademySalesChannel
                startDate={startDate}
                endDate={endDate} />

            <StatisticsAcademyCostAdvert
                startDate={startDate}
                endDate={endDate} /><br />

            <StatisticsSalesFunnel
                startDate={startDate}
                endDate={endDate} />

        </Layout>
    )
}
