/* eslint-disable jsx-a11y/anchor-is-valid */
import isEmpty from 'lodash/isEmpty'
import React, { Fragment, useState } from 'react'
import { usePostRequest } from '../hooks/request'
import { STUDENT_PAYMENT_DATE_LIST } from '../urls'
import CustomerInfoPaymentsItem from './CustomerInfoPaymentsItem'
import CustomerInfoPaymentsForm from './CustomerInfoPaymentsForm'

export default function CustomerInfoPayments({ customer, onUpdate, onDelete }) {
    const [showForm, setShowForm] = useState(false)
    const createStudent = usePostRequest({ url: STUDENT_PAYMENT_DATE_LIST })

    async function onCreate(data) {
        const { response, error } = await createStudent.request({ data: { ...data, customer: customer.id } })
        if (isEmpty(error)) {
            customer.paymentsDate.push(response)
            onUpdate(customer.id, customer)
        }
    }

    return (
        <Fragment>
            <span className="is-size-5">Дни оплаты</span>

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr />
                    <tr />
                    {customer.paymentsDate.map((item) => (
                        <CustomerInfoPaymentsItem
                            item={item}
                            key={item.id}
                            customer={customer}
                            onUpdate={onUpdate}
                            onDelete={onDelete} />
                    ))}

                    {showForm ? (
                        <CustomerInfoPaymentsForm
                            loading={createStudent.loading}
                            customer={customer}
                            onCancel={() => setShowForm(false)}
                            onSubmit={onCreate} />
                    ) : (
                        <tr>
                            <td colSpan="4">
                                <a onClick={() => setShowForm(true)}>
                                    <i className="icon ion-md-add" />
                                    Добавить
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    )
}
