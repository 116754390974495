import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Button from './common/Button'
import { useLoad } from '../hooks/request'
import { EMPLOYEE_LIST, NOTE_LIST } from '../urls'
import Select from './common/Select'
import Input from './common/Input'

export default function NoteItemForm({ initialValues, onSubmit, loading, onCancel }) {
    const employees = useLoad({ url: EMPLOYEE_LIST, params: { size: 1000 } })
    const employeesList = employees.response ? employees.response.results : []
    const note = useLoad({ url: NOTE_LIST })
    const options = (note.response ? note.response.results : []).map((item) => ({ id: item.id, name: item.mark }))
    return (
        <Formik initialValues={{ ...initialValues }} onSubmit={onSubmit}>
            <Form>
                <Select
                    name="note"
                    options={options}
                    label="Отметка"
                    loading={employees.loading}
                    help="Вы можете добавить новое примечание в настройках"
                    validate={required} />

                <Select
                    name="employee"
                    options={employeesList}
                    label="Сотрудник"
                    loading={employees.loading}
                    validate={required}
                    empty
                    disabled={!!initialValues}
                />

                <Input
                    name='comment'
                    label='Комментарий'
                    component='textarea'
                />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
