import React, { useState } from 'react'
import Button from './common/Button'
import { useLoad, usePostRequest } from '../hooks/request'
import { MESSAGE_LIST } from '../urls'
import { getDateTime } from "../utils/date";
import { Form, Formik } from "formik";
import Input from "./common/Input";
import { required } from "../utils/validators";
import Table from "./common/Table";

export default function StudentMessageList({ customer, onCancel }) {
    const [page, setPage] = useState(1)
    const initialValues = { text: '', customer: customer?.id }
    const messages = useLoad({ url: MESSAGE_LIST, params: { customer: customer?.id, page } }, [page]
    )
    const messageItem = messages.response ? messages.response.results : []
    const transactionCreate = usePostRequest()


    async function onSubmit(data, actions) {
        const { success } = await transactionCreate.request({
            data,
            url: MESSAGE_LIST,
        })
        if (success) {
            messages.request()
            actions.resetForm()
            onCancel()
        }
    }

    return (
        <div>

            {/*<b>Информация о студенте: {customer.name}</b><br/><br/>*/}

            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                <Form>
                    <b>Студент &nbsp; {customer ? customer.name : customer.name}</b><br/><br/>

                    <div className="columns">

                        <div className="column">
                            <Input
                                name="text"
                                placeholder="Введите сообщение"
                                validate={required}/>
                        </div>


                        <div className="column is-narrow">
                            <Button
                                loading={transactionCreate.loading}
                                text="Добавить"
                                type="submit"
                                icon="ion-md-add"
                                className="is-info"/>
                        </div>
                    </div>
                </Form>
            </Formik>


            <Table
                activePage={page}
                onPageChange={setPage}
                totalCount={messages.response ? messages.response.count : 0}
                loading={messages.loading}
                items={messageItem}
                columns={{ index: '', text: 'Сообщение', createdAt: 'Создан в' }}
                renderItem={(item, index) => (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{item.text}</td>
                        <td>{getDateTime(item.createdAt)}</td>
                    </tr>
                )}/>
            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger is-pulled-right mt-5 is-outlined"/> &nbsp;
        </div>
    )
}
