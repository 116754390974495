import React from 'react'
import { HOLIDAY_LIST } from '../urls'
import { usePostRequest } from '../hooks/request'
import HolidayForm from './HolidayForm'

export default function HolidayCreate({ onSuccess, onCancel }) {
    const roomCreate = usePostRequest({ url: HOLIDAY_LIST })

    async function onSubmit(data, actions) {
        await roomCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <HolidayForm loading={roomCreate.loading} onCancel={onCancel} onSuccess={onSuccess} onSubmit={onSubmit} />
        </div>
    )
}
