import React from 'react'
import { NOTE_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import NoteForm from './NoteForm'

export default function NoteUpdate({ onSuccess, onCancel, room }) {
    const roomUpdate = usePutRequest({ url: NOTE_DETAIL.replace('{id}', room.id) })

    async function onSubmit(data, actions) {
        await roomUpdate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <NoteForm
                loading={roomUpdate.loading}
                onCancel={onCancel}
                initialValues={room}
                onSuccess={onSuccess}
                onSubmit={onSubmit} />
        </div>
    )
}
